import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import useTranslations from '@hooks/useTranslations';
import { IPersonalizeOffer } from './index';
import useCustomRouter from '@hooks/useCustomRouter';
import { IconTemplate } from '@components/Icon/IconTemplate';
import { Icons } from '@components/Icon';
import Button from '@components/Button';
import { DepositTemplate } from '@components/Modal/ModalPersonalizeOffer/sections/DepositTemplate';
import { DealerDiscountTemplate } from '@components/Modal/ModalPersonalizeOffer/sections/DealerDiscountTemplate';
import { CommentTemplate } from '@components/Modal/ModalPersonalizeOffer/sections/CommentTemplate';
import { RegistrationFeesTemplate } from '@components/Modal/ModalPersonalizeOffer/sections/RegistrationFeesTemplate';
import {
    OVERALL_MILLEAGE_MAX_LENGTH,
    TradeInTemplate,
    VIN_REQUIRED_LENGTH,
} from '@components/Modal/ModalPersonalizeOffer/sections/TradeInTemplate';
import { AGENT_SOURCE_ID, OptionsTemplate } from '@components/Modal/ModalPersonalizeOffer/sections/OptionsTemplate';
import UIDuck from '../../../redux/commonDucks/ui.duck';
import { BUDGET_TYPE_CASH, BUDGET_TYPE_FINANCE, DealService } from '../../../services';
import { getSessionIdCookie } from '@utils/Session.utils';
import { useCarDetailsDuck } from '@hooks/useCarDetailsDuck';
import FinanceWidgetDuck from '../../../redux/financeWidget/financeWidget.duck';
import { CarDetailsDuck } from '../../../redux/carDetails/carDetails.duck';
import Spinner from '@components/Spinner';
import { theme } from '../../../styles/theme';
import { IDealerFee } from '@components/DealerFees/DealerFeesTemplate';
import { isBrandAC, REGISTRATION_FEE, REGISTRATION_FEES_VALUES } from '../../../constants/main';
import { useCarteGrise } from '@hooks/useCarteGrise';
import { POSTCODE_REGEX } from '@utils/checkoutInputsValidation';
import { useJourneyType } from '@hooks/useJourneyType';
import { useFeatureSwitchEnabled } from '@hooks/useFeatureSwitchEnabled';
import { FEATURES_LIST } from '../../../context/featureSwitchApp';
import { hasFinanceVariants } from '@utils/Price.utils';
import { useIgnorePXBonus } from '../../../partExchange/hooks';
import { useGTM } from '@hooks/useGTM';

export enum PERSONALIZE_OFFER_SECTIONS {
    DISCOUNT = 'discount',
    TRADE_IN = 'tradeIn',
    REGISTRATION_FEES = 'registrationFees',
    SUPPLEMENTS = 'supplements',
    DEPOSIT = 'deposit',
    COMMENT = 'comment',
}

export enum DEPOSIT_TYPE {
    ONLINE = 'online',
    OFFLINE = 'offline',
}

const DISCOUNT_ERROR_CODES = [400114];

const isNumber = (value: string | number) => /^[0-9]+$/.test(String(value));

export const ModalPersonalizeOfferTemplate = ({ className, openSection }: IPersonalizeOffer) => {
    const { t } = useTranslations();

    const dispatch = useDispatch();

    const { pushToDataLayer } = useGTM();

    const { updateCarteGrise } = useCarteGrise();

    const { currentDeal, carDetails } = useCarDetailsDuck();

    const { paymentJourneyType, isRent } = useJourneyType();
    const { hasSelectedPaymentJourney = false } = currentDeal?.extraFields ?? {};
    const hasSelectedFinance = hasSelectedPaymentJourney && !isRent && paymentJourneyType === BUDGET_TYPE_FINANCE;
    const hasSelectedCash = hasSelectedPaymentJourney && !isRent && paymentJourneyType === BUDGET_TYPE_CASH;

    const isEditDisabled = !hasSelectedFinance && !hasSelectedCash && hasFinanceVariants(carDetails?.prices);

    const [isLoading, setIsLoading] = useState(false);
    const [openSections, setOpenSections] = useState(openSection && !isEditDisabled ? [openSection] : []);

    const [customDepositAmount, setCustomDepositAmount] = useState(currentDeal?.extraFields?.dealerReservationFee);
    const [customDepositType, setCustomDepositType] = useState(
        currentDeal.extraFields.depositType || DEPOSIT_TYPE.ONLINE
    );
    const [customDealerDiscountAmount, setCustomDealerDiscountAmount] = useState(
        currentDeal?.extraFields?.agent_discount
            ? Number(JSON.parse(currentDeal?.extraFields?.agent_discount)?.amount)
            : null
    );
    const [hasDealerDiscountError, setHasDealerDiscountError] = useState(false);

    const [customDealerFees, setCustomDealerFees] = useState(
        currentDeal.extraFields.dealerFees
            ? JSON.parse(currentDeal.extraFields.dealerFees)
                  ?.filter((fee: IDealerFee) => fee.source === AGENT_SOURCE_ID && fee.id !== REGISTRATION_FEE)
                  .map((fee: IDealerFee) => ({
                      ...fee,
                      selected: !!carDetails.fees.find((selectedFee: { id: string }) => selectedFee.id === fee.id),
                  }))
            : []
    );
    const [customRegistrationFee, setCustomRegistrationFee] = useState(
        currentDeal?.extraFields?.dealerFees
            ? JSON.parse(currentDeal?.extraFields?.dealerFees)?.find((fee: IDealerFee) => fee.id === REGISTRATION_FEE)
                  ?.priceInclTax
            : null
    );
    const [customPostalCode, setCustomPostalCode] = useState(currentDeal?.extraFields?.postalCode);
    const [customPx, setCustomPx] = useState(
        currentDeal?.extraFields?.px ? JSON.parse(currentDeal?.extraFields?.px) : null
    );
    const [customComment, setCustomComment] = useState(
        currentDeal?.extraFields?.comments ? JSON.parse(currentDeal?.extraFields?.comments)?.[0]?.text : null
    );

    const [hasRequiredPostalCode, setHasRequiredPostalCode] = useState(false);
    const [hasInvalidPostalCode, setHasInvalidPostalCode] = useState(false);
    const [hasOptionRequiredError, setHasOptionRequiredError] = useState(false);
    const [hasTradeInRequiredError, setHasTradeInRequiredError] = useState(false);

    const isDealerFeesEnabled = useFeatureSwitchEnabled(FEATURES_LIST.FEATURE_SWITCH_DEALER_FEES_ENABLED);
    const isCarteGriseEnabled = useFeatureSwitchEnabled(FEATURES_LIST.FEATURE_SWITCH_CARTE_GRISE_ENABLED);

    const activeCustomPx = () => {
        if (!customPx) return null;
        if (customPx && !('isActive' in customPx))
            return { ...customPx, vehicle: { ...customPx.vehicle, isActive: true } };

        return customPx;
    };

    const apply = async () => {
        try {
            setIsLoading(true);
            setHasDealerDiscountError(false);
            setHasRequiredPostalCode(false);

            const previousRegistrationFee = currentDeal?.extraFields?.dealerFees
                ? JSON.parse(currentDeal?.extraFields?.dealerFees)?.find(
                      (fee: IDealerFee) => fee.id === REGISTRATION_FEE
                  )?.priceInclTax
                : null;
            if (
                isCarteGriseEnabled &&
                customRegistrationFee &&
                customRegistrationFee !== previousRegistrationFee &&
                !customPostalCode
            ) {
                setHasRequiredPostalCode(true);
                setOpenSections([PERSONALIZE_OFFER_SECTIONS.REGISTRATION_FEES]);
                setIsLoading(false);
                return;
            }

            if (isCarteGriseEnabled && customPostalCode && customPostalCode !== currentDeal?.extraFields?.postalCode) {
                setHasInvalidPostalCode(false);
                const isValid = POSTCODE_REGEX.test(customPostalCode);

                if (isValid) {
                    await updateCarteGrise(customPostalCode, true, REGISTRATION_FEES_VALUES.YES);
                } else {
                    setHasInvalidPostalCode(true);
                    setOpenSections([PERSONALIZE_OFFER_SECTIONS.REGISTRATION_FEES]);
                    setIsLoading(false);
                    return;
                }
            }

            if (customDealerFees?.length > 0) {
                setHasOptionRequiredError(false);

                if (
                    customDealerFees.filter(
                        (dealerFee: IDealerFee) => !isNumber(dealerFee.priceInclTax) || !dealerFee.title
                    )?.length > 0
                ) {
                    setHasOptionRequiredError(true);
                    setOpenSections([PERSONALIZE_OFFER_SECTIONS.SUPPLEMENTS]);
                    setIsLoading(false);
                    return;
                }
            }

            if (
                customPx?.vehicle?.makeName ||
                customPx?.vehicle?.modelName ||
                customPx?.vehicle?.vin ||
                customPx?.prices?.dealerValuation ||
                customPx?.vehicle?.registrationDate ||
                customPx?.prices?.dealerBonusAmount
            ) {
                setHasTradeInRequiredError(false);

                if (
                    !customPx?.vehicle?.makeName ||
                    !customPx?.vehicle?.modelName ||
                    !customPx?.vehicle?.vin ||
                    customPx?.vehicle?.vin?.length !== VIN_REQUIRED_LENGTH ||
                    !customPx?.vehicle?.vin?.match(/^[a-zA-Z0-9]+$/) ||
                    !isNumber(customPx?.vehicle?.overallMilleage) ||
                    String(customPx?.vehicle?.overallMilleage)?.length > OVERALL_MILLEAGE_MAX_LENGTH ||
                    !customPx?.prices?.dealerValuation ||
                    !customPx?.vehicle?.registrationDate
                ) {
                    setHasTradeInRequiredError(true);
                    setOpenSections([PERSONALIZE_OFFER_SECTIONS.TRADE_IN]);
                    setIsLoading(false);
                    return;
                }
            }

            const { data } = await DealService.updateDealAgentPortal(
                {
                    reservationFee: customDepositAmount >= 0 ? Number(customDepositAmount) : null,
                    depositType: customDepositType,
                    agent_discount: customDealerDiscountAmount
                        ? {
                              type: 'absolute',
                              amount: Number(customDealerDiscountAmount),
                          }
                        : null,
                    fees: customDealerFees,
                    px: activeCustomPx(),
                    comment: customComment,
                    dealerRegistrationFee: Number(customRegistrationFee),
                },
                getSessionIdCookie()
            );

            dispatch(CarDetailsDuck.setCurrentDealAndCarDetail(data.deal));
            dispatch(FinanceWidgetDuck.setFinanceQuote(data.financeQuote));
            dispatch(UIDuck.closeModal());

            setIsLoading(false);
        } catch (error: any) {
            if (DISCOUNT_ERROR_CODES.includes(error.response?.data?.code)) {
                setHasDealerDiscountError(true);
                setIsLoading(false);
                setOpenSections([]);
                return;
            }
        }
    };

    return (
        <div className={className}>
            {isEditDisabled && <div className="isDisabled_message">{t('modal.personalizeOffer.noEditable')}</div>}
            {hasDealerDiscountError && (
                <div className="isError_dealerDiscount">{t('modal.personalizeOffer.discount.error')}</div>
            )}
            <div className={`sections ${isEditDisabled ? 'isDisabled' : ''}`}>
                {Object.values(PERSONALIZE_OFFER_SECTIONS)
                    .filter((section) => {
                        if (section === PERSONALIZE_OFFER_SECTIONS.SUPPLEMENTS && !isDealerFeesEnabled) return;
                        if (section === PERSONALIZE_OFFER_SECTIONS.REGISTRATION_FEES && !isCarteGriseEnabled) return;

                        return section;
                    })
                    .map((section, index) => (
                        <div key={index} className={`section ${openSections.includes(section) ? 'isOpen' : ''}`}>
                            <div
                                className="section_title"
                                onClick={() =>
                                    openSections.includes(section)
                                        ? setOpenSections(openSections.filter((openSection) => openSection !== section))
                                        : setOpenSections([...openSections, section])
                                }
                            >
                                <span>{t(`modal.personalizeOffer.${section}.title`)}</span>
                                <IconTemplate name={Icons.ChevronDown} />
                            </div>
                            <div className="section_content">
                                {section === PERSONALIZE_OFFER_SECTIONS.DISCOUNT && openSections.includes(section) && (
                                    <DealerDiscountTemplate
                                        amount={customDealerDiscountAmount}
                                        setAmount={setCustomDealerDiscountAmount}
                                    />
                                )}
                                {section === PERSONALIZE_OFFER_SECTIONS.TRADE_IN && openSections.includes(section) && (
                                    <TradeInTemplate
                                        px={customPx}
                                        setPx={setCustomPx}
                                        hasRequiredError={hasTradeInRequiredError}
                                    />
                                )}
                                {section === PERSONALIZE_OFFER_SECTIONS.REGISTRATION_FEES &&
                                    openSections.includes(section) && (
                                        <RegistrationFeesTemplate
                                            registrationFee={customRegistrationFee}
                                            setRegistrationFee={setCustomRegistrationFee}
                                            postalCode={customPostalCode}
                                            setPostalCode={setCustomPostalCode}
                                            hasInvalidPostalCode={hasInvalidPostalCode}
                                            hasRequiredPostalCode={hasRequiredPostalCode}
                                        />
                                    )}
                                {section === PERSONALIZE_OFFER_SECTIONS.SUPPLEMENTS &&
                                    openSections.includes(section) && (
                                        <OptionsTemplate
                                            dealerFees={customDealerFees}
                                            setDealerFees={setCustomDealerFees}
                                            hasRequiredError={hasOptionRequiredError}
                                        />
                                    )}
                                {section === PERSONALIZE_OFFER_SECTIONS.DEPOSIT && openSections.includes(section) && (
                                    <DepositTemplate
                                        amount={customDepositAmount}
                                        setAmount={setCustomDepositAmount}
                                        customDealerDiscountAmount={customDealerDiscountAmount}
                                        depositType={customDepositType}
                                        setDepositType={setCustomDepositType}
                                    />
                                )}
                                {section === PERSONALIZE_OFFER_SECTIONS.COMMENT && openSections.includes(section) && (
                                    <CommentTemplate comment={customComment} setComment={setCustomComment} />
                                )}
                            </div>
                        </div>
                    ))}
            </div>
            <div className="buttons">
                {isLoading && <Spinner size={20} border={3} color={theme.colors.black} />}
                {!isLoading && (
                    <Button onClick={() => dispatch(UIDuck.closeModal())} secondary withoutBackground={isBrandAC}>
                        {t('modal.personalizeOffer.cancel')}
                    </Button>
                )}
                {!isLoading && (
                    <Button
                        onClick={() => {
                            pushToDataLayer({
                                event: 'uaevent',
                                eventCategory: 'Content::EditModal',
                                eventAction: 'Validate',
                                eventLabel: t('modal.personalizeOffer.validate'),
                            });
                            apply();
                        }}
                        primary
                        disabled={isEditDisabled}
                    >
                        {t('modal.personalizeOffer.validate')}
                    </Button>
                )}
            </div>
        </div>
    );
};
