import React, { FC, useContext, useEffect, useState } from 'react';
import { CharacteristicsProps } from '@components/v2/Characteristics/index';
import useTranslations from '@hooks/useTranslations';
import { useCarDetailsDuck } from '@hooks/useCarDetailsDuck';
import Collapsible from 'react-collapsible';
import { breakpoints, theme } from '../../../styles/theme';
import Icon, { Icons } from '@components/Icon';
import {
    isBrandAC,
    isBrandAP,
    isBrandDS,
    isBrandOV,
    isMarketGB,
    MARKET,
    ModalVersion,
    ROLES,
} from '../../../constants/main';
import UIDuck from '../../../redux/commonDucks/ui.duck';
import IconButton from '@components/IconButton';
import { ITechnicalSpecifications, ITechnicalSpecificationsData } from './';
import {
    getEnvironmentalAndTechData,
    getFilteredSortedTechSpecs,
    getPromotedTechnicalSpecifications,
    getSortedSpecs,
} from '@utils/Characteristics.utils';
import {
    CONSUMPTION_CO2,
    CONSUMPTION_KEY_WLTP,
    CONSUMPTION_MIX,
    ELECTRIC_RANGE,
    ENGINE_POWER,
    PROMOTED_SPECS_ORDER,
    STANDARD_FEATURES_FS_PREFIX,
    TECHNICAL_CHARACTERISTICS_FS_PREFIX,
} from '../../../constants/characteristics';
import { useTechSpec } from '@hooks/useTechSpec';
import { useFeatureSwitchEnabled } from '@hooks/useFeatureSwitchEnabled';
import { FEATURES_LIST, FeatureSwitchContext } from '../../../context/featureSwitchApp';
import { getData } from '@components/CarOptionsInfoBox/helpers';
import { getBootSpaceValue } from '@components/v2/Characteristics/helper/getStockEnviromentalData';
import { ICarDetail } from '../../../redux/carDetails/carDetails.duck.interface';
import { publicAssets } from '@utils/url.utils';
import { useRenderDescription } from '@hooks/useRenderDescription';
import { useDispatch } from 'react-redux';
import { useCarStockJourneyCheck } from '@hooks/useCarStockJourneyCheck';
import { getFuelTypeByCode } from '@utils/Fuel.utils';
import { usePlausible, UserEvent } from '@hooks/usePlausible';

export const CharacteristicsTemplate: FC<CharacteristicsProps> = ({ className, carDetails: parentCarDetails }) => {
    const { t } = useTranslations();

    const { carDetails: stateCarDetails } = useCarDetailsDuck();

    const carDetails = parentCarDetails || stateCarDetails;

    const { renderDescription } = useRenderDescription();

    const { plausibleUserEvent } = usePlausible();

    const dispatch = useDispatch();

    const [isMobile, setIsMobile] = useState(false);

    const { engine, technicalSpecifications, standardFeatures, fuel, gearbox } = carDetails;

    const { getConsumptionText, getConsumptionLabel, legalTextUrl, fuelLegalText } = useTechSpec(carDetails);

    const { isStockJourney } = useCarStockJourneyCheck();

    const excludedCategoriesStr =
        useContext(FeatureSwitchContext)?.FEATURE_SWITCH_EXCLUDED_CHARACTERISTICS_CATEGORIES ?? '';

    const detailTechs = technicalSpecifications && getPromotedTechnicalSpecifications(technicalSpecifications);
    const characteristicsTechs = standardFeatures && getSortedSpecs(standardFeatures);
    const technicalCharacteristicsTechs =
        technicalSpecifications && getFilteredSortedTechSpecs(technicalSpecifications);

    const isVisibleCO2Value = useFeatureSwitchEnabled(FEATURES_LIST.FEATURE_SWITCH_SHOW_CO2_VALUES);
    const isTiresLabellingEnabled = useFeatureSwitchEnabled(FEATURES_LIST.FEATURE_SWITCH_TIRES_LABELLING_ENABLED);
    const isCo2ImageEnabled = useFeatureSwitchEnabled(FEATURES_LIST.FEATURE_SWITCH_SHOW_CO2_IMAGE);

    const showCo2ImageSection =
        isCo2ImageEnabled && carDetails?.engine?.specs?.find((char: { key: string }) => char.key === 'co2BoardImage');

    const getPower = (carDetails: ICarDetail) => {
        return getData(carDetails.engine.specs, 'power', 'value');
    };

    const getTaxBandKey = (carDetails: ICarDetail) => {
        return getData(carDetails.engine.specs, 'taxBand', 'value');
    };

    const getCo2Image = () =>
        carDetails?.engine?.specs?.find((char: { key: string }) => char.key === 'co2BoardImage')?.value;

    const getCo2Value = () =>
        carDetails?.engine?.specs?.find((char: { key: string }) => char.key === 'co2Combined')?.value;

    const { environmentalData = null } = engine?.specs ? getEnvironmentalAndTechData(engine.specs) : {};

    const { isBenzin, isDiesel, isHybrid } = getFuelTypeByCode(carDetails.fuelCode);
    const isBenzinOrDiesel = isDiesel || isBenzin;

    const consumptionStars: string = isBrandOV ? '**' : '***';
    const localConsumption = getData(carDetails.engine.specs, 'consumptionCombined', 'local_value');
    const consumption = localConsumption ?? getData(carDetails.engine.specs, 'consumptionCombined', 'value');
    const localElectricRange = getData(carDetails.engine.specs, 'electricRange', 'local_value');
    const electricRange = localElectricRange ?? getData(carDetails.engine.specs, 'electricRange', 'value');

    useEffect(() => {
        setIsMobile(window.innerWidth < breakpoints.sm);
    }, []);

    return (
        <div className={className}>
            <Collapsible trigger="" open>
                <h2 className="title">{t('technicalFeatures.environmentalDetails.title')}</h2>
                <span className="subTitle">
                    <span>{engine.title}</span>
                    <span>{gearbox.title}</span>
                </span>
                <div className={`primaryTechs ${Object.keys(environmentalData)?.length === 3 ? 'withSpaces' : ''}`}>
                    <div className="primaryTechs_section fuel">
                        <Icon name={Icons.Fuel} color={theme.colors.primary} />
                        <span className="primaryTechs_section__detail">
                            <span
                                className="primaryTechs_section__detail___label"
                                data-testid="TESTING_TECH_LABEL_FUEL"
                            >
                                <strong>{t('technicalFeatures.environmentalDetails.engine.fuel')}</strong>
                            </span>
                            <span className="primaryTechs_section__detail___value">{fuel}</span>
                        </span>
                    </div>
                    <div className="primaryTechs_section energy">
                        <Icon name={Icons.Manual} color={theme.colors.primary} />
                        <span className="primaryTechs_section__detail">
                            <span
                                className="primaryTechs_section__detail___label"
                                data-testid="TESTING_TECH_LABEL_GEARBOX"
                            >
                                <strong>{t('technicalFeatures.environmentalDetails.gearbox')}</strong>
                            </span>
                            <span className="primaryTechs_section__detail___value">
                                {getData(gearbox.specs, 'type', 'label')}
                            </span>
                        </span>
                    </div>
                    {Object.keys(environmentalData).map((key: string) => {
                        const message: string = `technicalFeatures.environmentalDetails.${key}`;

                        const getDataTestId = (key: string) => {
                            if (key === ENGINE_POWER) return 'TESTING_TECH_LABEL_POWER';
                            if (key === CONSUMPTION_MIX) return 'TESTING_TECH_LABEL_CONSUMPTION_MIX';
                            if (key === CONSUMPTION_CO2) return 'TESTING_TECH_LABEL_CONSUMPTION_CO2';
                        };

                        return (
                            !isStockJourney && (
                                <div className="primaryTechs_section">
                                    {!isStockJourney && (
                                        <span className="primaryTechs_section__detail">
                                            <span
                                                className="primaryTechs_section__detail___label"
                                                data-testid={getDataTestId(key)}
                                            >
                                                {t(message)}
                                            </span>
                                            <span className="primaryTechs_section__detail___value">
                                                <strong>
                                                    {environmentalData[key]}
                                                    {key !== ELECTRIC_RANGE && t(`${message}.label`)}
                                                </strong>
                                            </span>
                                        </span>
                                    )}
                                </div>
                            )
                        );
                    })}
                </div>
                <div className="secondaryTechs">
                    <span className="sectionTitle">{t('configurator.info.strongPoints')}</span>

                    <div className="secondaryTechs_sections">
                        <div className="secondaryTechs_sections__section">
                            <span className="sectionSubTitle">
                                {t('technicalFeatures.environmentalDetails.specification')}
                            </span>
                            {getBootSpaceValue(carDetails) && (
                                <div>
                                    <p>{t('configurator.info.boot')}</p>
                                    <span>
                                        <strong>{getBootSpaceValue(carDetails)}</strong>
                                    </span>
                                </div>
                            )}
                            {isBenzinOrDiesel && (
                                <>
                                    {
                                        <div>
                                            <p data-testid="TESTING_TECH_INFO_POWER">{t('configurator.info.power')}</p>
                                            <span>
                                                <strong>{getPower(carDetails)}</strong>
                                            </span>
                                        </div>
                                    }
                                    <div>
                                        <p>{t('configurator.info.mileage')}</p>
                                        <span data-testid="TESTING_TECH_INFO_CONSUMPTION">
                                            <strong>{`${consumption} ${t(
                                                'configurator.info.consumptionCombined.label'
                                            )}`}</strong>
                                        </span>
                                    </div>
                                    <div className="tech-point emmisions">
                                        <p>
                                            {t('configurator.info.emmisions')}
                                            <sub>2</sub>
                                        </p>
                                        <span>
                                            <strong>{`${getData(carDetails.engine.specs, 'co2Combined', 'value')} ${t(
                                                'configurator.info.emmisions.label'
                                            )}`}</strong>
                                        </span>
                                    </div>
                                </>
                            )}
                            {isHybrid && (
                                <>
                                    {
                                        <div>
                                            <p>
                                                {t('technicalFeatures.environmentalDetails.consumption.electricRange')}
                                            </p>
                                            <span>
                                                <strong>
                                                    {electricRange} {t('configurator.info.power.label')}
                                                </strong>
                                            </span>
                                        </div>
                                    }
                                    <div>
                                        <p>
                                            {t(
                                                'technicalFeatures.environmentalDetails.consumption.electricConsumption'
                                            )}
                                        </p>
                                        <span>
                                            <strong>
                                                {getData(carDetails.engine.specs, 'electricConsumption', 'value')}
                                            </strong>
                                        </span>
                                    </div>
                                    <div>
                                        <p>{t('configurator.info.mileage')}</p>
                                        <span>
                                            <strong>{`${getData(
                                                carDetails.engine.specs,
                                                'consumptionCombined',
                                                'value'
                                            )} l/100km${consumptionStars}`}</strong>
                                        </span>
                                    </div>
                                    <div>
                                        <p>
                                            {t('configurator.info.emmisions')}
                                            <sub>2</sub>
                                        </p>
                                        <span>
                                            <strong>{`${getData(
                                                carDetails.engine.specs,
                                                'co2Combined',
                                                'value'
                                            )} g/km${consumptionStars}`}</strong>
                                        </span>
                                    </div>
                                </>
                            )}
                            {!isHybrid && !isBenzinOrDiesel && (
                                <>
                                    {
                                        <div>
                                            <p>
                                                {t('technicalFeatures.environmentalDetails.consumption.electricRange')}
                                            </p>
                                            <span>
                                                <strong>
                                                    {electricRange} {t('configurator.info.power.label')}
                                                </strong>
                                            </span>
                                        </div>
                                    }
                                    <div>
                                        <p>
                                            {t(
                                                'technicalFeatures.environmentalDetails.consumption.electricConsumption'
                                            )}
                                        </p>
                                        <span>
                                            <strong>
                                                {getData(carDetails.engine.specs, 'electricConsumption', 'value')}{' '}
                                                {t(
                                                    'technicalFeatures.environmentalDetails.consumption.electricConsumption.label'
                                                )}
                                            </strong>
                                        </span>
                                    </div>
                                    <div>
                                        <p>
                                            {t('configurator.info.emmisions')}
                                            <sub>2</sub>
                                        </p>
                                        <span>
                                            <strong>{`${getData(
                                                carDetails.engine.specs,
                                                'co2Combined',
                                                'value'
                                            )} g/km${consumptionStars}`}</strong>
                                        </span>
                                    </div>
                                </>
                            )}
                            {isVisibleCO2Value && getTaxBandKey(carDetails) && (
                                <div>
                                    <p>{t('configurator.info.co2')}</p>
                                    <span>
                                        <img
                                            src={publicAssets(
                                                `/public/images/co2/${MARKET}/farbskala_gross_${getTaxBandKey(
                                                    carDetails
                                                )}.jpg`
                                            )}
                                        />
                                    </span>
                                </div>
                            )}
                        </div>
                        {!!(carDetails.extraFields?.seriesEquipment?.length || carDetails?.seriesEquipment?.length) && (
                            <div className="secondaryTechs_sections__section">
                                <span className="sectionSubTitle">
                                    {t('technicalFeatures.environmentalDetails.benefits')}
                                </span>
                                <ul>
                                    {(carDetails.extraFields?.seriesEquipment ?? carDetails.seriesEquipment).map(
                                        (category: { data: any }) => {
                                            if (Array.isArray(category.data)) {
                                                return category.data.map((equipment: any) => (
                                                    <li key={equipment.label}>{equipment.label}</li>
                                                ));
                                            } else if (category.data?.label) {
                                                return <li key={category.data.label}>{category.data.label}</li>;
                                            } else {
                                                return Object.keys(category.data).map((key) => (
                                                    <li key={category.data[key].label}>{category.data[key].label}</li>
                                                ));
                                            }
                                        }
                                    )}
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
                <div className="detailTechs">
                    <span className="sectionTitle">{t('technicalFeatures.environmentalDetails.details')}</span>

                    <div className="detailTechs_sections">
                        {detailTechs &&
                            detailTechs.length &&
                            detailTechs.map((spec: ITechnicalSpecifications, categoryKey: number) => (
                                <Collapsible
                                    open={!isMobile}
                                    key={`detailTech_${categoryKey}`}
                                    trigger={
                                        <span className="sectionSubTitle withSpace">
                                            {spec.category} {isMobile && <Icon name={Icons.ChevronDown} />}
                                        </span>
                                    }
                                >
                                    <div className="detailTechs_sections__section">
                                        {spec.data.map((data: ITechnicalSpecificationsData, specKey: number) => {
                                            return (
                                                <div
                                                    className="detailTechs_sections__section___value"
                                                    key={`detailTech_section_${specKey}`}
                                                >
                                                    <span dangerouslySetInnerHTML={{ __html: data?.label }} />
                                                    <span>
                                                        <strong>{data?.value}</strong>
                                                    </span>
                                                </div>
                                            );
                                        })}
                                        {spec.key === CONSUMPTION_KEY_WLTP && showCo2ImageSection && (
                                            <div className="detailTechs_sections__section___value">
                                                <span>{t('configurator.label.co2')}</span>
                                                <span>
                                                    <img
                                                        alt={`${t('configurator.label.co2')} ${getCo2Value()}`}
                                                        className="featureImageList"
                                                        src={getCo2Image() as string}
                                                    />
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                </Collapsible>
                            ))}
                    </div>

                    {fuelLegalText && legalTextUrl && (
                        <p className="legalText">
                            {fuelLegalText}
                            <a href={legalTextUrl} target="_blank" rel="noreferrer">
                                {legalTextUrl}
                            </a>
                            .
                        </p>
                    )}
                </div>
                <div className="characteristicsTechs">
                    <span className="sectionTitle">{t('technicalFeatures.environmentalDetails.specification')}</span>
                </div>
                <div className="characteristicsTechs">
                    <div className="characteristicsTechs_sections">
                        {Boolean(characteristicsTechs && characteristicsTechs.length) &&
                            characteristicsTechs.map((spec: ITechnicalSpecifications, index: number) => {
                                if (!excludedCategoriesStr?.includes(`${STANDARD_FEATURES_FS_PREFIX}:${spec.key}`)) {
                                    return (
                                        <div
                                            key={`characteristicsTechs_sections_${index}`}
                                            className="characteristicsTechs_sections__section withSpace"
                                        >
                                            <Collapsible
                                                open={!isMobile}
                                                trigger={
                                                    <span className="sectionSubTitle withSpace">
                                                        {spec.category} {isMobile && <Icon name={Icons.ChevronDown} />}
                                                    </span>
                                                }
                                            >
                                                <ul>
                                                    {spec.data.map((data: ITechnicalSpecificationsData, i: number) => {
                                                        return (
                                                            <li key={`specData_${i}`}>
                                                                <span>
                                                                    {data.label} {data.value}{' '}
                                                                    {(data.description ||
                                                                        (isTiresLabellingEnabled && data.tires)) && (
                                                                        <IconButton
                                                                            className="infoIcon tires-icon"
                                                                            icon={
                                                                                isBrandAC ||
                                                                                isBrandAP ||
                                                                                isBrandDS ||
                                                                                (isBrandOV && !isMarketGB)
                                                                                    ? Icons.InfoCircle
                                                                                    : Icons.Info
                                                                            }
                                                                            color={theme.colors.primary}
                                                                            width={16}
                                                                            height={16}
                                                                            role={ROLES.IMG}
                                                                            onClick={() => {
                                                                                plausibleUserEvent(
                                                                                    UserEvent.StandardEquipmentDetailsOpenFromConfigPage
                                                                                );
                                                                                dispatch(
                                                                                    UIDuck.openModal({
                                                                                        data: {
                                                                                            category: spec?.category,
                                                                                            image: data.images?.[0]?.[
                                                                                                'url'
                                                                                            ],
                                                                                            description:
                                                                                                renderDescription(
                                                                                                    data.description
                                                                                                ),
                                                                                            title: data.label,
                                                                                            ...(isTiresLabellingEnabled &&
                                                                                                data.tires && {
                                                                                                    tiresSubtitle: t(
                                                                                                        'technicalFeatures.tiresDetailModal.title'
                                                                                                    ),
                                                                                                    tires: data.tires
                                                                                                        ?.data,
                                                                                                    tiresDescription: t(
                                                                                                        'technicalFeatures.tiresDetailModal.subtitle'
                                                                                                    ),
                                                                                                }),
                                                                                        },
                                                                                        modalType:
                                                                                            UIDuck.MODAL_TYPES
                                                                                                .CAR_OPTION_DETAIL,
                                                                                        modalVersion: ModalVersion.v2,
                                                                                    })
                                                                                );
                                                                            }}
                                                                            dataTestId="SVG_IMG_TYRES_TECHCHARS"
                                                                            altId="svgImgTyresTechChars"
                                                                            altText={t('option.detail.shortText.info')}
                                                                        />
                                                                    )}
                                                                </span>
                                                            </li>
                                                        );
                                                    })}
                                                </ul>
                                            </Collapsible>
                                        </div>
                                    );
                                }
                            })}
                    </div>
                </div>
                <div className="characteristicsTechs">
                    <div className="characteristicsTechs_sections">
                        {Boolean(technicalCharacteristicsTechs && technicalCharacteristicsTechs.length) &&
                            technicalCharacteristicsTechs.map((spec: ITechnicalSpecifications, index: number) => {
                                const { category, key } = spec;
                                if (excludedCategoriesStr?.includes(`${TECHNICAL_CHARACTERISTICS_FS_PREFIX}:${key}`)) {
                                    return null;
                                }

                                if (PROMOTED_SPECS_ORDER[key]) {
                                    return null;
                                }

                                return (
                                    Boolean(spec?.data?.length) && (
                                        <div
                                            className="characteristicsTechs_sections__section withSpace"
                                            key={`technicalCharacteristicsTechs_${index}`}
                                        >
                                            <Collapsible
                                                open={!isMobile}
                                                trigger={
                                                    <span className="sectionSubTitle withSpace">
                                                        {spec.category} {isMobile && <Icon name={Icons.ChevronDown} />}
                                                    </span>
                                                }
                                            >
                                                <div>
                                                    {spec.data.map(
                                                        (data: ITechnicalSpecificationsData, index: number) => {
                                                            return (
                                                                <span
                                                                    key={index}
                                                                    className="characteristicsTechs_sections__section___value"
                                                                >
                                                                    <span
                                                                        dangerouslySetInnerHTML={{ __html: data.label }}
                                                                    />
                                                                    <span>{data.value}</span>
                                                                </span>
                                                            );
                                                        }
                                                    )}
                                                </div>
                                            </Collapsible>
                                        </div>
                                    )
                                );
                            })}
                    </div>
                </div>
            </Collapsible>
        </div>
    );
};
