import React, { CSSProperties, FC, useCallback, useEffect, useMemo, useState } from 'react';
import UIDuck from '../../redux/commonDucks/ui.duck';
import { Dispatch } from 'redux';
import Icon, { Icons } from '../Icon';
import { connect, useSelector } from 'react-redux';
import { ModalDeleteDeal } from './ModalDeleteDeal';
import ModalFinancialSummary from './ModalFinancialSummary';
import { ModalDealerFeesPostalCode } from './ModalDealerFeesPostalCode';
import { ModalDealerFeesPostalCodeError } from './ModalDealerFeesPostalCodeError';
import { ModalOrderReviewError } from './ModalOrderReviewError';
import { ModalOrderReviewRedirectError } from './ModalOrderReviewRedirectError';
import InvalidConfigurationModalContent from './InvalidConfigurationModalContent';
import ModalFinancialSummaryConfiguration from './ModalFinancialSummaryConfiguration';
import ModalCarOptionDetail from './ModalCarOptionDetail';
import ModalShareConfigurationDealerCode from './ModalShareConfigurationDealerCode';
import ModalFindDealer from './ModalFindDealer';
import ModalDealerSearch from './ModalDealerSearch';
import useTranslations from '@hooks/useTranslations';
import ModalMissingParts from './ModalMissingParts';
import ModalFinanceLegal from './ModalFinanceLegal';
import ModalLeadForm from '../ModalLeadForm';
import ModalPaymentMock from './ModalPaymentMock';
import ModalStockVehicleUnavalaible from './ModalStockVehicleUnavalaible';
import { ModalFeatureSwitches } from './ModalFeatureSwitches';
import { ModalShareConfigurationBasket } from './ModalShareConfigurationBasket';
import { ModalShareConfiguration } from './ModalShareConfiguration';
import { ModalScrappage } from './ModalScrappage';
import { ModalEngineCompare } from '@components/Modal/ModalComparators/ModalEngineCompare/ModalEngineCompare';
import AccessibleButton from '@components/Button/AccessibleButton';
import ModalShareOfferSummary from './ModalShareOfferSummary';
import ModalDisableCheckout from '@components/Modal/ModalDisableCheckout';
import { FocusPlaceholder } from './ModalStyled';
import { ModalPostalCode } from '@components/Modal/ModalPostalCode';
import FilterDuck from '../../redux/filters/filter.duck';
import ModalFeatures from '@components/Modal/ModalFeatures';
import ModalTrimCompare from '@components/Modal/ModalComparators/ModalTrimCompare';
import ModalFeature from '@components/Modal/ModalFeatureDetail';
import { theme } from '../../styles/theme';
import { ModalVersion } from '../../constants/main';
import { getModelTitle } from '../../services/carList/utils/Car.utils';
import { useRouter } from 'next/router';
import { buildParameterizedPathname } from '@utils/url.utils';
import routes from '../../constants/routes';
import Routes from '../../constants/routes';
import { CarJourneyType } from '../../services';
import { IModalCallbacks, IUIDuck } from 'src/redux/commonDucks/ui.duck.interface';
import { Redux } from 'src/redux/redux.interface';
import { ModalNeedHelp } from '@components/Modal/ModalNeedHelp';
import { ModalSave } from '@components/Modal/ModalSave/Save';
import DealerDuck from '../../redux/dealer/dealer.duck';
import { ModalSaveYourProject } from '@components/Modal/ModalSave/SaveYourProject';
import { ModalPersonalizeOffer } from '@components/Modal/ModalPersonalizeOffer';
import ModalShareConfigurationPersonalizeOffer from '@components/Modal/ModalShareConfigurationPersonalizeOffer';
import { ModalOfferExpired } from '@components/Modal/ModalOfferExpired';
import { ModalSessionChange } from '@components/Modal/ModalSessionChange';
import { ModalLogout } from '@components/Modal/ModalLogout';
import { ModalIncompletePx } from '@components/Modal/ModalIncompletePx';
import { ModalRemovePxConfirmation } from '@components/Modal/ModalRemovePxConfirmation';

const SCROLLABLE_MODAL_TYPES_DESKTOP_MOBILE = [
    UIDuck.MODAL_TYPES.FINANCIAL_SUMMARY_CONFIGURATION,
    UIDuck.MODAL_TYPES.FIND_DEALER,
    UIDuck.MODAL_TYPES.LEAD_FORM,
    UIDuck.MODAL_TYPES.DEALER_SEARCH,
    UIDuck.MODAL_TYPES.TRIM_COMPARE,
    UIDuck.MODAL_TYPES.FEATURES,
    UIDuck.MODAL_TYPES.PERSONALIZE_OFFER,
];

const FULL_HEIGHT_MODALS = [UIDuck.MODAL_TYPES.CAR_OPTION_DETAIL];

const SCROLLABLE_MODAL_TYPES_MOBILE = [
    UIDuck.MODAL_TYPES.SHARE_CONFIGURATION_DEALER_CODE,
    UIDuck.MODAL_TYPES.LEAD_FORM,
];

export interface IModal {
    isModalOpen: boolean;
    modalData: any;
    modalType: string;
    dispatch: Dispatch;
    className?: string;
    modalCallbacks: IModalCallbacks;
    modalVersion?: ModalVersion;
    modalProperties?: {
        withoutPadding?: boolean;
        fullWidthOnDesktop?: boolean;
        middleWidthOnDesktop?: boolean;
        centerModeOnDesktop?: boolean;
    };
}

export interface IState {
    isShowTitle: boolean;
    isShowBackButton: boolean;
    onBackClick?: () => void;
    onCloseModal?: ({ cb }?: { cb?: () => void }) => void;
}

export const MODAL_WRAPPER_ID = 'modal_wrapper';
const MODAL_HEADER_ID = 'modal_header';
const MODAL_BODY_ID = 'modal_body';

const Modal: FC<IModal> = ({
    isModalOpen,
    modalData,
    modalVersion = ModalVersion.v1,
    modalProperties,
    modalCallbacks,
    modalType,
    dispatch,
    className,
}) => {
    const { t } = useTranslations();
    const router = useRouter();
    const geoLocation = useSelector((state: Redux) => FilterDuck.getGeoLocation(state));
    const dealer = useSelector((state: Redux) => DealerDuck.getOwnState(state).dealer);
    const [resetLocationInput, setResetLocationInput] = useState<boolean>(false);

    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        setIsOpen(!!modalType);

        const modal = document?.getElementById(`${MODAL_WRAPPER_ID}`);
        const header = document?.getElementById(`${MODAL_HEADER_ID}`);
        const body = document?.getElementById(`${MODAL_BODY_ID}`);

        modal?.addEventListener('scroll', () => {
            if (header) {
                header.style.boxShadow =
                    modal.scrollTop > header.offsetHeight ? `0 2px 6px -2px ${theme.colors.grey12}` : 'unset';
            }
        });

        setTimeout(() => {
            if (modal) {
                const isModalHeightSmallerAsContent =
                    modal.clientHeight + 5 < body.offsetHeight + (header?.offsetHeight || 0);
                const isMobileWidth = modal.clientWidth < 480;

                if (SCROLLABLE_MODAL_TYPES_MOBILE.includes(modalType) && isMobileWidth) {
                    modal.style.overflowY = 'scroll';
                    modal.style.height = '100%';
                    return;
                }

                if (
                    !SCROLLABLE_MODAL_TYPES_DESKTOP_MOBILE.includes(modalType) &&
                    !FULL_HEIGHT_MODALS.includes(modalType) &&
                    isMobileWidth
                ) {
                    modal.style.height = isModalHeightSmallerAsContent ? '100%' : 'fit-content';
                }

                if (SCROLLABLE_MODAL_TYPES_DESKTOP_MOBILE.includes(modalType) || isModalHeightSmallerAsContent) {
                    modal.style.overflowY = 'scroll';
                } else if (FULL_HEIGHT_MODALS.includes(modalType)) {
                    modal.style.overflowY = 'unset';
                }
            }
        }, 100);
    }, [modalType]);

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.removeProperty('overflow');
        }
    }, [isOpen]);

    const closeModal = useCallback(
        (isDirectClickOnCloseButton: boolean = false) => {
            modalCallbacks?.onClose?.();
            dispatch(UIDuck.closeModal());
            if (
                modalType === UIDuck.MODAL_TYPES.STOCK_CAR_UNAVAILABLE &&
                router.pathname.includes(Routes.CHECKOUT_ORDER_REVIEW)
            ) {
                router.push(buildParameterizedPathname(routes.ROOT, CarJourneyType.STOCK));
            }
            if (isDirectClickOnCloseButton && modalType === UIDuck.MODAL_TYPES.POSTAL_CODE && !geoLocation && !dealer) {
                router.push(buildParameterizedPathname(routes.ROOT, CarJourneyType.STOCK));
            }
            if (modalCallbacks?.openPreviousModalType) {
                dispatch(
                    UIDuck.openModal({
                        data: { ...modalCallbacks?.data },
                        callbacks: {},
                        modalType: modalCallbacks?.openPreviousModalType,
                        ...(modalCallbacks?.modalProperties && { modalProperties: modalCallbacks?.modalProperties }),
                        modalVersion: ModalVersion.v2,
                    })
                );
            }
        },
        [modalCallbacks, dispatch, geoLocation]
    );

    const initialModalState: IState = {
        isShowTitle: true,
        isShowBackButton: false,
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onBackClick: () => {},
        onCloseModal: ({ cb }) => {
            closeModal();
            cb?.();
        },
    };

    const [modal, setModal] = useState<IState>(initialModalState);

    useEffect(() => {
        if (!isModalOpen) {
            setModal(initialModalState);
            setResetLocationInput(true);
        }
    }, [modalType, isModalOpen]);

    const clickBuster = useCallback(
        (event) => {
            if (
                event.currentTarget === event.target &&
                modalType !== UIDuck.MODAL_TYPES.PERSONALIZE_OFFER &&
                modalType !== UIDuck.MODAL_TYPES.LOGOUT &&
                modalType !== UIDuck.MODAL_TYPES.SESSION_CHANGE
            ) {
                event.preventDefault();
                closeModal();
            }
        },
        [closeModal]
    );

    const onSubmit = useCallback(
        (...args: any[]) => {
            modalCallbacks?.onClick('submit', ...args);
            dispatch(UIDuck.closeModal());
        },
        [modalCallbacks, dispatch]
    );

    // close modal if ESC key is pressed
    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                closeModal();
            }
        };
        if (isModalOpen) {
            document.addEventListener('keydown', handleKeyDown);
        }
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [isModalOpen, closeModal]);

    const getContext = (type: string) => {
        switch (type) {
            case UIDuck.MODAL_TYPES.DELETE_DEAL:
                return <ModalDeleteDeal onSubmit={onSubmit} onClose={closeModal} />;
            case UIDuck.MODAL_TYPES.ENGINE_COMPARE:
                return (
                    <ModalEngineCompare
                        disableSelection={modalData.disableSelection}
                        motorizations={modalData.motorizations}
                        onSubmit={onSubmit}
                        setModal={setModal}
                        modal={modal}
                        displayBackBtn={modalData.displayBackBtn}
                    />
                );
            case UIDuck.MODAL_TYPES.SCRAPPAGE:
                return <ModalScrappage {...modalData} onSubmit={onSubmit} />;
            case UIDuck.MODAL_TYPES.PERSONALIZE_OFFER:
                return <ModalPersonalizeOffer {...modalData} />;
            case UIDuck.MODAL_TYPES.INCOMPLETE_PX:
                return <ModalIncompletePx {...modalData} />;
            case UIDuck.MODAL_TYPES.REMOVE_PX_CONFIRMATION:
                return <ModalRemovePxConfirmation {...modalData} />;
            case UIDuck.MODAL_TYPES.EXAMPLE:
                return <div>{modalData.title}</div>;
            case UIDuck.MODAL_TYPES.FIND_DEALER:
                return <ModalFindDealer onClose={closeModal} resetLocationInput={resetLocationInput} />;
            case UIDuck.MODAL_TYPES.DEALER_FEES_POSTAL_CODE:
                return <ModalDealerFeesPostalCode onSubmit={onSubmit} onClose={closeModal} />;
            case UIDuck.MODAL_TYPES.DEALER_FEES_POSTAL_CODE_ERROR:
                return <ModalDealerFeesPostalCodeError onClose={closeModal} />;
            case UIDuck.MODAL_TYPES.SHARE_CONFIGURATION_DEALER_CODE:
                return <ModalShareConfigurationDealerCode {...modalData} closeModal={closeModal} />;
            case UIDuck.MODAL_TYPES.SHARE_CONFIGURATION_PERSONALIZE_OFFER:
                return <ModalShareConfigurationPersonalizeOffer {...modalData} closeModal={closeModal} />;
            case UIDuck.MODAL_TYPES.DISABLE_CHECKOUT:
                return <ModalDisableCheckout closeModal={closeModal} />;
            case UIDuck.MODAL_TYPES.DEALER_SEARCH:
                return <ModalDealerSearch {...modalData} onSubmit={onSubmit} />;
            case UIDuck.MODAL_TYPES.ORDER_REVIEW_ERROR:
                return <ModalOrderReviewError onClose={closeModal} />;
            case UIDuck.MODAL_TYPES.ORDER_REVIEW_REDIRECT_ERROR:
                return <ModalOrderReviewRedirectError onClose={closeModal} />;
            case UIDuck.MODAL_TYPES.FINANCIAL_SUMMARY:
                return <ModalFinancialSummary {...modalData} />;
            case UIDuck.MODAL_TYPES.FINANCIAL_SUMMARY_CONFIGURATION:
                return (
                    <ModalFinancialSummaryConfiguration
                        {...{
                            error: modalData?.error,
                            data: modalData?.financialData?.data,
                            hideLegalNotice: modalData.hideLegalNotice,
                        }}
                        setModal={setModal}
                        modal={modal}
                    />
                );
            case UIDuck.MODAL_TYPES.CAR_OPTION_DETAIL:
                return <ModalCarOptionDetail {...modalData} onClose={closeModal} />;
            case UIDuck.MODAL_TYPES.OFFER_EXPIRED:
                return <ModalOfferExpired rrdi7={modalData.rrdi7} expiredAt={modalData.expiredAt} />;
            case UIDuck.MODAL_TYPES.SESSION_CHANGE:
                return (
                    <ModalSessionChange
                        continueWithNewConfiguration={modalData.continueWithNewConfiguration}
                        changeWithPreviousConfiguration={modalData.changeWithPreviousConfiguration}
                        newConfiguration={modalData.newConfiguration}
                        previousConfiguration={modalData.previousConfiguration}
                        version={modalData.version}
                    />
                );
            case UIDuck.MODAL_TYPES.LOGOUT:
                return <ModalLogout />;
            case UIDuck.MODAL_TYPES.POSTAL_CODE:
                return (
                    <ModalPostalCode
                        {...modalData}
                        setModal={setModal}
                        onClose={closeModal}
                        searchType={modalData.searchType}
                    />
                );
            case UIDuck.MODAL_TYPES.INVALID_CONFIGURATION:
                return <InvalidConfigurationModalContent data={modalData} />;
            case UIDuck.MODAL_TYPES.MISSING_PARTS:
                return (
                    <ModalMissingParts
                        message={modalData.message}
                        additionalMessage={modalData.additionalMessage}
                        buttonText={modalData.buttonText}
                        onClick={() => {
                            closeModal();
                            modalData?.onClose();
                        }}
                    />
                );
            case UIDuck.MODAL_TYPES.FINANCE_LEGAL:
                return (
                    <ModalFinanceLegal
                        message={modalData.message}
                        additionalMessage={modalData.additionalMessage}
                        buttonText={modalData.buttonText}
                        onClick={closeModal}
                    />
                );
            case UIDuck.MODAL_TYPES.LEAD_FORM:
                return <ModalLeadForm {...modalData} />;
            case UIDuck.MODAL_TYPES.SHARE_OFFER_SUMMARY:
                return <ModalShareOfferSummary {...modalData} />;
            case UIDuck.MODAL_TYPES.PAYMENT_MOCK:
                return <ModalPaymentMock onClick={modalCallbacks.onClick} onClose={closeModal} />;
            case UIDuck.MODAL_TYPES.STOCK_CAR_UNAVAILABLE:
                return <ModalStockVehicleUnavalaible onClose={closeModal} {...modalData} />;
            case UIDuck.MODAL_TYPES.FEATURES:
                return <ModalFeatures onClose={closeModal} {...modalData} />;
            case UIDuck.MODAL_TYPES.TRIM_COMPARE:
                return <ModalTrimCompare onClose={closeModal} {...modalData} />;
            case UIDuck.MODAL_TYPES.FEATURE:
                return <ModalFeature onClose={closeModal} {...modalData} />;
            case UIDuck.MODAL_TYPES.NEED_HELP:
                return <ModalNeedHelp onClose={modalCallbacks?.onClose} {...modalData} />;
            case UIDuck.MODAL_TYPES.SAVE_YOUR_PROJECT:
                return <ModalSaveYourProject onClose={modalCallbacks?.onClose} page={modalData?.page} />;
            case UIDuck.MODAL_TYPES.SAVE:
                return <ModalSave onClose={modalCallbacks?.onClose} page={modalData?.page} />;
            case UIDuck.MODAL_TYPES.FEATURE_SWITCHES:
                return <ModalFeatureSwitches onClose={closeModal} />;
            case UIDuck.MODAL_TYPES.SHARE_CONFIGURATION:
                return <ModalShareConfiguration onClose={closeModal} email={modalData?.email} />;
            case UIDuck.MODAL_TYPES.SHARE_CONFIGURATION_BASKET:
                return <ModalShareConfigurationBasket {...modalData} />;
            default:
                return null;
        }
    };
    const getDataTestIdForTitle = (type: string) => {
        switch (type) {
            case UIDuck.MODAL_TYPES.FEATURES:
                return 'TESTING_FEATURES_MODAL_TITLE';
            default:
                return null;
        }
    };
    const getTitle = (type: string) => {
        switch (type) {
            case UIDuck.MODAL_TYPES.DELETE_DEAL:
                return t('dialog.deleteDeal.title');
            case UIDuck.MODAL_TYPES.EXAMPLE:
                return 'Le xamplee';
            case UIDuck.MODAL_TYPES.FINANCIAL_SUMMARY:
                return 'DETAIL DU FINANCEMENT';
            case UIDuck.MODAL_TYPES.FINANCIAL_SUMMARY_CONFIGURATION:
                return (
                    modalData.data?.globalTexts?.detailTitle ||
                    (modalData?.isLeasysDetail ? t('renting.detailTitle') : t('financial.detailTitle'))
                );
            case UIDuck.MODAL_TYPES.INVALID_CONFIGURATION:
                return t('modal.configurator.invalidConfiguration.title');
            case UIDuck.MODAL_TYPES.MISSING_PARTS:
                return modalData.title;
            case UIDuck.MODAL_TYPES.LEAD_FORM:
                return t('configurator.interestedBox3.link');
            case UIDuck.MODAL_TYPES.PAYMENT_MOCK:
                return t('checkout.payment.modal.title');
            case UIDuck.MODAL_TYPES.FEATURE_SWITCHES:
                return t('featureSwitches.modal.title');
            case UIDuck.MODAL_TYPES.INCOMPLETE_PX:
                return t('modal.incompletePx.title');
            case UIDuck.MODAL_TYPES.REMOVE_PX_CONFIRMATION:
                return t('modal.removePx.title');
            case UIDuck.MODAL_TYPES.SHARE_OFFER_SUMMARY:
                return t('shareConfiguration.getEmail');
            case UIDuck.MODAL_TYPES.SHARE_CONFIGURATION_DEALER_CODE:
                return t('modal.dealerShareConfiguration.title');
            case UIDuck.MODAL_TYPES.SHARE_CONFIGURATION_PERSONALIZE_OFFER:
                return t('modal.dealerShareConfiguration.title');
            case UIDuck.MODAL_TYPES.DISABLE_CHECKOUT:
                return t('checkoutDisable.modal.title');
            case UIDuck.MODAL_TYPES.OFFER_EXPIRED:
                return t('modal.offerExpired.title');
            case UIDuck.MODAL_TYPES.SESSION_CHANGE:
                return t('modal.sessionChange.title');
            case UIDuck.MODAL_TYPES.LOGOUT:
                return t('modal.logout.title');
            case UIDuck.MODAL_TYPES.ORDER_REVIEW_ERROR:
                return t('checkout.order.error');
            case UIDuck.MODAL_TYPES.DEALER_FEES_POSTAL_CODE_ERROR:
                return t('basket.dealerFee.carteGrise.error.title');
            case UIDuck.MODAL_TYPES.NEED_HELP:
                return t('header.nav.needHelp');
            case UIDuck.MODAL_TYPES.SAVE_YOUR_PROJECT:
                return t('header.nav.save');
            case UIDuck.MODAL_TYPES.SAVE:
                return t('header.nav.save');
            case UIDuck.MODAL_TYPES.POSTAL_CODE:
                return t('modal.postalCode.title');
            case UIDuck.MODAL_TYPES.STOCK_CAR_UNAVAILABLE:
                return t('stockVehicleUnavailable.title');
            case UIDuck.MODAL_TYPES.SHARE_CONFIGURATION:
                return t('modal.shareConfiguration.title');
            case UIDuck.MODAL_TYPES.DEALER_SEARCH:
                return t('modal.dealerShareConfiguration.dealer.search');
            case UIDuck.MODAL_TYPES.SHARE_CONFIGURATION_BASKET:
                return t('collectConsentTitle');
            case UIDuck.MODAL_TYPES.PERSONALIZE_OFFER:
                return t('modal.personalizeOffer.title');
            case UIDuck.MODAL_TYPES.SCRAPPAGE:
                return t('dialog.scrappage.title');
            case UIDuck.MODAL_TYPES.FEATURES:
                return getModelTitle(modalData?.carDetails, modalData?.carDetails?.displayGrBodyStyleLabel);
            case UIDuck.MODAL_TYPES.FINANCE_LEGAL:
                return modalData.title;
            default:
                return null;
        }
    };

    const getExtraClass = (type: string) => {
        switch (type) {
            case UIDuck.MODAL_TYPES.FINANCE_LEGAL:
                return 'finance-legal';
            case UIDuck.MODAL_TYPES.ENGINE_COMPARE:
                return 'engines';
            case UIDuck.MODAL_TYPES.TRIM_COMPARE:
                return 'trim-compare';
            default:
                return null;
        }
    };

    const getWidthClass = (type: string) => {
        switch (type) {
            case UIDuck.MODAL_TYPES.TRIM_COMPARE:
                return 'trimCompareCustomWidth';
            case UIDuck.MODAL_TYPES.FEATURES:
            case UIDuck.MODAL_TYPES.INCOMPLETE_PX:
            case UIDuck.MODAL_TYPES.REMOVE_PX_CONFIRMATION:
                return 'featureCustomWidth';
            case UIDuck.MODAL_TYPES.POSTAL_CODE:
                return 'postalCodeCustomWidth';
            case UIDuck.MODAL_TYPES.SESSION_CHANGE:
                return 'sessionChangeCustomWidth';
            default:
                return 'defaultCustomWidth';
        }
    };

    const isPermanentPopup = (type: string) => {
        switch (type) {
            case UIDuck.MODAL_TYPES.FINANCE_LEGAL:
            case UIDuck.MODAL_TYPES.POSTAL_CODE:
                return true;
            default:
                return false;
        }
    };

    const isWithoutOverlay = (type: string) => {
        switch (type) {
            case UIDuck.MODAL_TYPES.FINANCE_LEGAL:
                return 'no-overlay';
            default:
                return '';
        }
    };

    const initModalStyles = useCallback((): CSSProperties => {
        if (modalType === UIDuck.MODAL_TYPES.ENGINE_COMPARE) {
            return {
                overflowY: 'scroll',
            };
        }

        return {};
    }, [modalType]);

    const modalV2ClassName = useMemo((): string => {
        const {
            fullWidthOnDesktop = false,
            middleWidthOnDesktop = false,
            centerModeOnDesktop = false,
        } = modalProperties;

        const baseClass = 'modalSidebar__wrap';
        const extraClass = getExtraClass(modalType);
        const openClass = isOpen && modalType !== UIDuck.MODAL_TYPES.POSTAL_CODE ? 'isOpen' : '';
        const closeClass = !isOpen && modalType !== UIDuck.MODAL_TYPES.POSTAL_CODE ? 'isClose' : '';
        const fullWidthClass = fullWidthOnDesktop ? 'fullWidth' : '';
        const middleWidthClass = middleWidthOnDesktop ? 'middleWidth' : '';
        const defaultWidthClass = !fullWidthOnDesktop && !middleWidthOnDesktop ? getWidthClass(modalType) : '';
        const centerClass = centerModeOnDesktop ? 'center' : '';

        return `${baseClass} ${extraClass} ${openClass} ${closeClass} ${fullWidthClass} ${middleWidthClass} ${defaultWidthClass} ${centerClass}`.trim();
    }, [modalType, isOpen, modalProperties]);

    const modalSideBarClassName = useMemo(
        () => `modalSidebar ${modalProperties?.centerModeOnDesktop ? 'center' : ''} ${isWithoutOverlay(modalType)}`,
        [modalProperties, modalType]
    );

    if (!isModalOpen) {
        return null;
    }

    if (modalVersion === ModalVersion.v2) {
        return (
            <div className={className}>
                <div className={modalSideBarClassName} onClick={!isPermanentPopup(modalType) && clickBuster}>
                    <div className={modalV2ClassName} id={MODAL_WRAPPER_ID} style={initModalStyles()}>
                        {getTitle(modalType) && (
                            <div className="modalSidebar__wrap__header" id={MODAL_HEADER_ID}>
                                <span
                                    className="modalSidebar__wrap__header__title"
                                    data-testid={getDataTestIdForTitle(modalType)}
                                >
                                    {modalType === UIDuck.MODAL_TYPES.DEALER_SEARCH && (
                                        <button className="modalSidebar__wrap__header__backBtn" id="modalBackBtn">
                                            <Icon height={25} name={Icons.chevronLeftV2} />
                                        </button>
                                    )}
                                    {getTitle(modalType)}
                                </span>
                                {modalType !== UIDuck.MODAL_TYPES.POSTAL_CODE &&
                                    modalType !== UIDuck.MODAL_TYPES.LOGOUT && (
                                        <button
                                            className={'modalSidebar__wrap__header__closeBtn'}
                                            onClick={() => closeModal(true)}
                                            data-testid="TESTING_CLOSE_MODAL"
                                        >
                                            &#10005;
                                        </button>
                                    )}
                            </div>
                        )}
                        <div
                            className={`modalSidebar__wrap__modalBody ${
                                !modalProperties?.withoutPadding ? 'withPadding' : ''
                            }`}
                            id={MODAL_BODY_ID}
                        >
                            {getContext(modalType)}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className={className}>
            <div
                className={`modalWindow ${isWithoutOverlay(modalType)}`}
                onClick={!isPermanentPopup(modalType) && clickBuster}
            >
                <div className={`modalWindow__wrap ${getExtraClass(modalType)}`}>
                    <div className="modalWindow__wrap__header">
                        {modal.isShowBackButton && (
                            <button className="modalWindow__wrap__header__back" onClick={modal.onBackClick}>
                                <Icon name={Icons.chevronLeftV2} width={45} height={45} />
                            </button>
                        )}
                        {modal.isShowTitle && (
                            <p
                                className="modalWindow__wrap__header__title"
                                data-testid={getDataTestIdForTitle(modalType)}
                            >
                                {getTitle(modalType)}
                            </p>
                        )}
                        {modalType !== UIDuck.MODAL_TYPES.SCRAPPAGE && (
                            <>
                                <FocusPlaceholder autoFocus type="button" />
                                <AccessibleButton
                                    className={'modalWindow__wrap__header__closeBtn'}
                                    onClick={closeModal}
                                    data-testid="TESTING_CLOSE_MODAL"
                                >
                                    &#10005;
                                </AccessibleButton>
                            </>
                        )}
                    </div>
                    <div className="modalWindow__wrap__modalBody">{getContext(modalType)}</div>
                </div>
            </div>
        </div>
    );
};
const mapStateToProps = (state: IUIDuck) => {
    return {
        isModalOpen: UIDuck.isModalOpen(state),
        modalData: UIDuck.getModalData(state),
        modalCallbacks: UIDuck.getModalCallbacks(state),
        modalType: UIDuck.getModalType(state),
        modalVersion: UIDuck.getModalVersion(state),
        modalProperties: UIDuck.getModalProperties(state),
    };
};

export default connect(mapStateToProps)(Modal);
