import React, { useEffect, useState } from 'react';
import useTranslations from '@hooks/useTranslations';
import { IModalRemovePxConfirmation } from './index';
import Button from '@components/Button';
import UIDuck from '../../../redux/commonDucks/ui.duck';
import { useDispatch } from 'react-redux';
import Spinner from '@components/Spinner';
import { isBrandAC } from '../../../constants/main';

export const ModalRemovePxConfirmationTemplate = ({ className, onConfirmClick }: IModalRemovePxConfirmation) => {
    const { t } = useTranslations();

    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);

    const onClose = async () => {
        dispatch(UIDuck.closeModal());
    };

    const onContinue = async () => {
        try {
            setIsLoading(true);
            await onConfirmClick();
            setIsLoading(false);
            dispatch(UIDuck.closeModal());
        } catch {}
    };

    return (
        <div className={className}>
            {isLoading && <Spinner size={25} border={2} color="black" />}
            {!isLoading && (
                <div className="button">
                    <Button onClick={onClose} primary={!isBrandAC} secondary={isBrandAC}>
                        {t('modal.removePx.button.close')}
                    </Button>
                    <Button onClick={onContinue} secondary withoutBackground={isBrandAC}>
                        {t('modal.removePx.button.continue')}
                    </Button>
                </div>
            )}
        </div>
    );
};
